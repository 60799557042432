import cx from 'classnames';
interface Props {
  children: any;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  className?: string;
  style?: object;
  onClick?: Function;
}

const Button: React.FC<Props> = ({
  type,
  children,
  disabled,
  className,
  onClick,
  ...props
}) => {
  function eventClick() {
    if (onClick) {
      onClick();
    }
  }

  return (
    <>
      <button
        type={type}
        className={cx('btn', className)}
        disabled={disabled}
        onClick={eventClick}
        {...props}
      >
        {children}
      </button>
    </>
  );
};

Button.defaultProps = {
  disabled: false,
  type: 'button',
};

export default Button;
