import FormControl from '@client/components/FormControl';
import TextField from '@client/components/TextField';
import Box from '@client/components/Box';
import Button from '@client/components/Button';
import { required } from '@client/utils/Validators';
import { FormEventHandler, useState } from 'react';
import FormGroup from '@client/components/FormGroup';
import { CAPTCHA_SITE_KEY } from '@config/environment';
import ReCAPTCHA from 'react-google-recaptcha';

interface Props {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  recaptchaRef: any;
  eventRecaptcha: Function;
  recaptcha: any;
}

const LoginForm: React.FC<Props> = ({
  handleSubmit,
  recaptchaRef,
  eventRecaptcha,
  recaptcha,
}) => {
  const [type, setType] = useState('password');

  const showPwd = () => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };

  return (
    <>
      <Box as="form" onSubmit={handleSubmit}>
        <Box className="row">
          <Box className="col-sm-12">
            <FormGroup name="username" label="Email">
              <FormControl
                as={TextField}
                name="username"
                placeholder="Input Email"
                label="Email"
                validate={[required]}
              />
            </FormGroup>
          </Box>

          <Box className="col-sm-12">
            <FormGroup name="password" label="Password">
              <FormControl
                as={TextField}
                name="password"
                type={type}
                showPwd
                togglePwd={showPwd}
                placeholder="Input Password"
                label="Password"
                validate={[required]}
              />
            </FormGroup>
          </Box>

          <Box className="d-flex justify-content-center my-10">
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY}
              ref={recaptchaRef}
              onChange={() => eventRecaptcha(true)}
              onExpired={() => eventRecaptcha(false)}
            />
          </Box>

          <Box className="col-sm-12">
            <Button
              type="submit"
              className="btn-primer w-100 mt-3"
              disabled={!recaptcha}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginForm;
