import { FormApi } from 'final-form';
import { Form as FinalForm } from 'react-final-form';

const Form: React.FC<any> = ({
  render: Component,
  onSubmit,
  values,
  ...props
}) => {
  const eventSubmit = (data: any, form: FormApi) => {
    if (onSubmit) {
      onSubmit(data, form);
    }
  };

  return (
    <>
      <FinalForm
        onSubmit={eventSubmit}
        initialValues={values}
        subscription={{ submitting: true, pristine: true }}
      >
        {(form) => <Component {...form} {...props} />}
      </FinalForm>
    </>
  );
};

export default Form;
