import { Field } from 'react-final-form';

interface Props {
  name: string;
  label: string;
  type?: string;
  as: any;
  onChange?: Function;
  onReset?: Function;
  placeholder: string;
  validate?: Array<any>;
  className?: string;
  format?: string;
  mask?: string;
  prefix?: string;
  suffix?: string;
  dispel?: boolean;
  showPwd?: boolean;
  togglePwd?: any;
  allowEmptyFormatting?: boolean;
  disabled?: boolean;
  options?: Array<any>;
  decimalSeparator?: string;
  thousandSeparator?: string;
  id?: string;
  accept?: string;
  url?: any;
  isForm?: any;
}

const FormControl: React.FC<Props> = ({
  name,
  onChange,
  as: Component,
  validate,
  className,
  ...props
}) => {
  const eventValidate = (validate?: Array<Function>) => {
    if (validate !== undefined) {
      // make validation is array
      const validators = Array.isArray(validate) ? validate : [validate];

      // convert into anonymous functions
      const mappingValidators = validators.map((validator) => {
        return validator['name'] === '' ? validator : validator();
      });

      return (value: any, data: any) => {
        return mappingValidators.reduce((error, validator) => {
          return error || validator(value, data, props.label);
        }, undefined);
      };
    }
  };

  const eventChange = (name: string, value: any) => {
    if (onChange) {
      onChange(name, value);
    }
  };

  return (
    <>
      <Field name={name} validate={eventValidate(validate)}>
        {({ input, meta }) => {
          return (
            <>
              <Component
                {...input}
                {...props}
                className={className}
                onChange={(name: string, value: any) => {
                  input.onChange(value);
                  eventChange(name, value);
                }}
              />

              {meta.error && meta.touched && (
                <div className="error">{meta.error}</div>
              )}
            </>
          );
        }}
      </Field>
    </>
  );
};

export default FormControl;
