import Box from '../Box';
import Button from '../Button';

interface Props {
  disabled?: boolean;
  readOnly?: boolean;
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: Function;
  onBlur?: Function;
  dispel?: boolean;
  showPwd?: boolean;
  togglePwd?: any;
  type?: 'text' | 'number' | 'email' | 'tel' | 'password' | 'hidden';
  className?: string;
}

const TextField: React.FC<Props> = ({
  onChange,
  onBlur,
  className,
  dispel,
  showPwd,
  togglePwd,
  disabled,
  ...props
}) => {
  const { name, type, placeholder } = props;

  const eventChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (onChange) {
      onChange(name, value);
    }
  };

  const eventBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (onBlur) {
      onBlur(name, value);
    }
  };

  const resetInput = () => {
    if (onChange) {
      onChange(name, 0);
    }
  };

  return (
    <Box className="d-flex flex-row">
      {dispel && !showPwd && (
        <>
          <input
            {...props}
            name={name}
            type={type}
            className={className}
            placeholder={placeholder}
            onChange={eventChange}
            onBlur={eventBlur}
            style={{ backgroundColor: '#fcfcfd', color: '#A0A3BD' }}
          />
          <Button
            className="btn-primer py-2 px-3"
            style={{ marginLeft: '10px' }}
            onClick={resetInput}
          >
            Reset
          </Button>
        </>
      )}
      {!dispel && showPwd && (
        <>
          <input
            {...props}
            name={name}
            type={type}
            className={className}
            placeholder={placeholder}
            onChange={eventChange}
            onBlur={eventBlur}
          />
          {type === 'password' && (
            <i
              className="far fa-eye"
              id="togglePassword"
              onClick={togglePwd}
              style={{
                marginLeft: '-40px',
                marginTop: '18px',
                cursor: 'pointer',
              }}
            />
          )}
          {type === 'text' && (
            <i
              className="far fa-eye-slash"
              id="togglePassword"
              onClick={togglePwd}
              style={{
                marginLeft: '-41px',
                marginTop: '18px',
                cursor: 'pointer',
              }}
            />
          )}
        </>
      )}
      {!dispel && !showPwd && disabled && (
        <input
          {...props}
          name={name}
          type={type}
          className={className}
          placeholder={placeholder}
          onChange={eventChange}
          onBlur={eventBlur}
          disabled
        />
      )}
      {!dispel && !showPwd && !disabled && (
        <input
          {...props}
          name={name}
          type={type}
          className={className}
          placeholder={placeholder}
          onChange={eventChange}
          onBlur={eventBlur}
        />
      )}
    </Box>
  );
};

TextField.defaultProps = {
  disabled: false,
  readOnly: false,
  onChange: () => null,
};

export default TextField;
