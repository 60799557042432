interface Props {
  name: string;
  label?: string;
  children: any;
}

const FormGroup: React.FC<Props> = ({ children, ...props }) => {
  const { name, label } = props;

  return (
    <div className="mb-3">
      <label className="form-label" htmlFor={name}>
        <strong>{label}</strong>
      </label>
      {children}
    </div>
  );
};

export default FormGroup;
